<!--
- Reference List > Modal > Share Selected References
-->

<template>
	<generic-modal-wrap modal-id="ShareReferences">
		<h2 class="nodal-title">
            {{ modalTitle}}
		</h2>

        <div class="form-group">
            <label>Share with these people</label>
            <div class="help-text mb-1">
                Add the email of the person you'd like to share your reference(s) with. Separate multiple emails with a comma.
            </div>
            
            <div class="form-control-fake">
                <span v-for="(emailToShare, index) in arrayOfEmailsToShare" :key="index" class="single-blip">
                    <span class="blip-text">
                        {{ emailToShare }}
                    </span>
                    <span class="blip-delete" @click.prevent="btnBlipDelete(index)" role="button" aria-label="Delete Email" title="Delete Email">
                        <span class="svg bi-x-lg" />
                    </span>
                </span>

                <input
                    autocomplete="off"
                    :class="['form-control-email-enter', {'ms-1': arrayOfEmailsToShare.length > 0}]"	
                    :placeholder="(arrayOfEmailsToShare.length === 0) ? 'Enter an email address' : 'Enter another email address'"
                    type="text"
                    v-model="emailToAdd"
                    @paste="onPasteEmails"
                    @keydown="handleKeyDown" />
            </div>
            
            <div class="help-text mb-1" v-if="arrayOfEmailsToShare.length > 0">
                {{ arrayOfEmailsToShare.length }} email<span v-if="arrayOfEmailsToShare.length > 1">s</span>
            </div>
        </div>

        <div class="form-group">
            <label>Include Research Notes?</label>
            <div class="help-text mb-1">
                Do you want to include the Research Notes for the selected references?
            </div>
            <div class="row">
				<div class="col">
					<div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="radio"
							id="shareReferences_IncludeResearchNotesTrue"
							name="shareReferences_IncludeResearchNotes" 
                            :value="true"
							v-model="isShareResearchNotes" /> 
						<label class="form-check-label" for="shareReferences_IncludeResearchNotesTrue">Yes - include Research Notes</label>
					</div>
                    <div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="radio"
							id="shareReferences_IncludeResearchNotesFalse"
							name="shareReferences_IncludeResearchNotes" 
                            :value="false"
							v-model="isShareResearchNotes" /> 
						<label class="form-check-label" for="shareReferences_IncludeResearchNotesFalse">No</label>
					</div>
                </div><!--col-->
            </div>
        </div>

        <div class="form-group">
            <label>Type a message (optional)</label>
            <div class="help-text mb-1">
                If you would like to include a message along with your shared references, type it below.
            </div>
            <textarea
                class="form-control"
                placeholder="Type a message here"
                rows="3"
                maxlength="250"
                v-model="message" />
            
            <div class="help-text mb-1">
                {{ 250 - message.length }}/250 characters
            </div>
        </div>

        <div class="mt-2 row justify-content-between">
            <div class="col">
                <div class="d-grid">
                    <button
                        class="btn btn-outline-danger"
                        type="button"
                        @click.prevent="btnCloseModal">
                        Nevermind
                    </button>
                </div>
            </div><!--col-->
            <div class="col">
                <div class="d-grid">
                    <button class="btn btn-success" type="submit" @click.prevent="btnShareMyReferences" :disabled="isShareButtonDisabled">
                        Share My References
                    </button>
                </div>
            </div><!--col-->
        </div>
	</generic-modal-wrap>
</template>

<script lang="js">
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import isEmail from 'validator/lib/isEmail';	//https://www.npmjs.com/package/validator

export default {
	name: 'ModalPageCount',
    data(){
        return {
            arrayOfEmailsToShare: [],
            emailToAdd: '',
            isShareResearchNotes: true,
            message: '',
        }
    },
	computed:{
        isShareButtonDisabled(){
            if(
                this.arrayOfEmailsToShare.length > 0 && 
                !_isEmpty(this.$store.state.modals.passData) &&
                _has(this.$store.state.modals.passData, 'referencesIdsToShare') &&
                this.$store.state.modals.passData.referencesIdsToShare.length > 0
            ){
                return false;
            }
            return true;
        },

		modalTitle(){
			if(!_isEmpty(this.$store.state.modals.passData) && _has(this.$store.state.modals.passData, 'referencesIdsToShare')){
                if(this.$store.state.modals.passData.referencesIdsToShare.length === 1){
                    return 'Share Reference';
                } else {
                    return 'Share ' + this.$store.state.modals.passData.referencesIdsToShare.length + ' References';
                }
			}
			return 'Share References';
		},
        shareSuccessMessage(){
            if(!_isEmpty(this.$store.state.modals.passData) && _has(this.$store.state.modals.passData, 'referencesIdsToShare')){
                if(this.$store.state.modals.passData.referencesIdsToShare.length === 1){
                    return 'Reference Shared';
                } else {
                    return this.$store.state.modals.passData.referencesIdsToShare.length + ' References Shared';
                }
			}
			return 'References Shared';
        },
	},
    methods:{
        btnCloseModal(){
            this.$store.commit('modals/CLOSE', 'ShareReferences');
            this.clearLocalFormData();
        },
        
        btnConvertEmailToChip(){
            if(
                this.emailToAdd !== '' && // make sure the field isn't blank
                isEmail(this.emailToAdd) && // make sure the email is valid format
                !this.arrayOfEmailsToShare.includes(this.emailToAdd) && // make sure this email isn't already added
                this.emailToAdd !== this.$store.state.customer.email // a user can't share with themselves
            ){
                this.arrayOfEmailsToShare.push(this.emailToAdd);
                setTimeout(()=>{
                    this.emailToAdd = '';
                }, 1);
            }//e:if
        },//e:btnConvertEmailToChip

        btnBlipDelete(indexOfEmailToRemove){
            this.arrayOfEmailsToShare.splice(indexOfEmailToRemove, 1);
        },//e:btnBlipDelete

        btnShareMyReferences(){
            this.$store.commit('loaders/ADD_ID', 'App');

            let sendBody = {
                "message": this.message,
                "includeNotes": this.isShareResearchNotes,
                "emails": this.arrayOfEmailsToShare,
                "referenceIDs": this.$store.state.modals.passData.referencesIdsToShare
            }
            
            console.log('sendBody');
            console.log(sendBody);

            ApiS_MakeCall({
               body: sendBody,
				method: 'POST',
				url: 'v4/GenesisReference/Share',
			}).then(()=>{
                // setTimeout(()=>{
                this.$store.commit('loaders/REMOVE_ID', 'App');
                this.$nextTick(()=>{
                    this.$store.commit('modals/CLOSE', 'ShareReferences')
                    
                    this.clearLocalFormData();

                    this.$nextTick(()=>{
                        this.emitter.emit('globalToasterOpen',{
                            textContent: this.shareSuccessMessage,
                        });
                    });//e:nextTick
                });//e:nextTick	
                // }, 1500);
			});//e:ApiS_MakeCall

        },//e:btnShareMyReferences
        
        clearLocalFormData(){
            this.arrayOfEmailsToShare = [];
            this.isShareResearchNotes = true;
            this.message = '';
        },

        handleKeyDown(event){
            if(
                event.keyCode === 13 ||     // enter
                event.keyCode === 188 ||    // comma
                event.keyCode === 32        // space
            ){
                this.btnConvertEmailToChip();
            }
        },
        // riley@perrla.com, graysen@perrla.com,joey@perrla.com, notreal@@m,  riley@perrla.com
        onPasteEmails(event){
            // let validEmailsToUse = [];

            let pasteString = event.clipboardData.getData('text');
            pasteString = pasteString.replace(/\s/g, '');
            
            let pasteSplitArray = pasteString.split(',');
            pasteSplitArray.forEach((singleEmail)=>{
                if(
                    isEmail(singleEmail) &&
                    !this.arrayOfEmailsToShare.includes(singleEmail) &&
                    singleEmail !== this.$store.state.customer.email // a user can't share with themselves
                ){
                    // validEmailsToUse.push(singleEmail);
                    this.arrayOfEmailsToShare.push(singleEmail)
                }

            });
            setTimeout(()=>{
                this.emailToAdd = '';
            }, 0);
        },//e:onPasteEmails
    },
    created(){
        this.emitter.on('GenericModalClose', ($opts)=>{
			if($opts.modalId === 'ShareReferences'){
                this.clearLocalFormData();
			}
		});
    },
    mounted(){
        this.clearLocalFormData();
    },
	components:{
		GenericModalWrap
	},
    destroyed(){
        this.emitter.off('GenericModalClose');
    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/vars_perrla";

@include color-mode(dark) {
    .form-control-email-enter{
        background-color: $gray-800;
    }
}

.form-control-fake{
    height: auto;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
    

    .form-control-email-enter {
        border: none;
        line-height: 1;
        padding: 0;
        margin: 0.5rem 0;
        width: 100%;
        
        &:focus{
		    outline: none;
            border: none;
	    }
    }
}

.single-blip {
    background-color: $secondary-100;
    color: $body-color;
    border-radius: $border-radius;
	position: relative;
	padding: 0.25rem 0.5rem;
    float:left;
	font-size: 0.9rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
    

    .blip-text {
        padding-right: 0.25rem;
        margin-right: 0.25rem;
        border-right: 1px solid $secondary-300;
    }
    .blip-delete {
        font-size: 0.75rem;
    }
}

</style>