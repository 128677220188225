var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "ShareReferences" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n            " + _vm._s(_vm.modalTitle) + "\n\t\t"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Share with these people")]),
        _vm._v(" "),
        _c("div", { staticClass: "help-text mb-1" }, [
          _vm._v(
            "\n                Add the email of the person you'd like to share your reference(s) with. Separate multiple emails with a comma.\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-control-fake" },
          [
            _vm._l(_vm.arrayOfEmailsToShare, function (emailToShare, index) {
              return _c("span", { key: index, staticClass: "single-blip" }, [
                _c("span", { staticClass: "blip-text" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(emailToShare) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "blip-delete",
                    attrs: {
                      role: "button",
                      "aria-label": "Delete Email",
                      title: "Delete Email",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnBlipDelete(index)
                      },
                    },
                  },
                  [_c("span", { staticClass: "svg bi-x-lg" })]
                ),
              ])
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emailToAdd,
                  expression: "emailToAdd",
                },
              ],
              class: [
                "form-control-email-enter",
                { "ms-1": _vm.arrayOfEmailsToShare.length > 0 },
              ],
              attrs: {
                autocomplete: "off",
                placeholder:
                  _vm.arrayOfEmailsToShare.length === 0
                    ? "Enter an email address"
                    : "Enter another email address",
                type: "text",
              },
              domProps: { value: _vm.emailToAdd },
              on: {
                paste: _vm.onPasteEmails,
                keydown: _vm.handleKeyDown,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.emailToAdd = $event.target.value
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.arrayOfEmailsToShare.length > 0
          ? _c("div", { staticClass: "help-text mb-1" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.arrayOfEmailsToShare.length) +
                  " email"
              ),
              _vm.arrayOfEmailsToShare.length > 1
                ? _c("span", [_vm._v("s")])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Include Research Notes?")]),
        _vm._v(" "),
        _c("div", { staticClass: "help-text mb-1" }, [
          _vm._v(
            "\n                Do you want to include the Research Notes for the selected references?\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check form-check-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isShareResearchNotes,
                    expression: "isShareResearchNotes",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "shareReferences_IncludeResearchNotesTrue",
                  name: "shareReferences_IncludeResearchNotes",
                },
                domProps: {
                  value: true,
                  checked: _vm._q(_vm.isShareResearchNotes, true),
                },
                on: {
                  change: function ($event) {
                    _vm.isShareResearchNotes = true
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "shareReferences_IncludeResearchNotesTrue" },
                },
                [_vm._v("Yes - include Research Notes")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check form-check-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isShareResearchNotes,
                    expression: "isShareResearchNotes",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "shareReferences_IncludeResearchNotesFalse",
                  name: "shareReferences_IncludeResearchNotes",
                },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.isShareResearchNotes, false),
                },
                on: {
                  change: function ($event) {
                    _vm.isShareResearchNotes = false
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "shareReferences_IncludeResearchNotesFalse" },
                },
                [_vm._v("No")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Type a message (optional)")]),
        _vm._v(" "),
        _c("div", { staticClass: "help-text mb-1" }, [
          _vm._v(
            "\n                If you would like to include a message along with your shared references, type it below.\n            "
          ),
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message",
            },
          ],
          staticClass: "form-control",
          attrs: {
            placeholder: "Type a message here",
            rows: "3",
            maxlength: "250",
          },
          domProps: { value: _vm.message },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.message = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "help-text mb-1" }, [
          _vm._v(
            "\n                " +
              _vm._s(250 - _vm.message.length) +
              "/250 characters\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2 row justify-content-between" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnCloseModal.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Nevermind\n                    "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit", disabled: _vm.isShareButtonDisabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnShareMyReferences.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Share My References\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }