/*
- errorsToIgnore
-- a new object for each Platform Type, i'll check the correct one based on platform id when i need to
-- 2024.12.06
**/


export default {
	SPA:[
        `TypeError: t.getModifierState is not a function`,
        `ResizeObserver loop limit exceeded`,
        `ResizeObserver loop completed with undelivered notifications.`,
    ]
}

