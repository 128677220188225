<!--
- References > List
-- sort & search grid
-- each row is a reference 
-->

<template>
	<div>
		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				References
			</h1>

			<button class="btn btn-success float-start me-3" type="button" @click.prevent="btnCreateReference">
				<span class="svg bi-plus-lg me-1" /> Create a new reference
			</button>
			
			<div class="dropdown float-end" v-show="!isReferenceOptionsOpen">
				<a class="btn-dropdown dropdown-toggle" href="#" role="button" @click.prevent="btnToggleReferenceOptionsOpen">
					Show Options
				</a>
			</div>
			<div class="dropdown float-end dropup" v-show="isReferenceOptionsOpen">
				<a class="btn-dropdown dropdown-toggle" href="#" role="button" @click.prevent="btnToggleReferenceOptionsOpen">
					Hide Options
				</a>
			</div>
			
			<!-- <button v-if="$store.state.app.showBetaFeatures" class="btn btn-outline-warning float-start me-3" title="Rebuild References" @click.prevent="btnRebuildGrid" type="button">
				<span class="svg icons8-undo" />
			</button> -->

			<div class="flex-fill">
				<div class="dropdown float-end">
					<a class="btn-dropdown dropdown-toggle me-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						{{ preferredFormatVersionIdOutput }}
					</a>
					
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.APA7,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.APA7
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.APA7)">
								APA 7
							</a>
						</li>
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.MLA9,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.MLA9
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.MLA9)">
								MLA 9
							</a>
						</li>
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.Turabian9,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.Turabian9
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.Turabian9)">
								Turabian 9
							</a>
						</li>
					</ul>
				</div><!--dropdown-->
			</div>
			
			<grid-search
				class="ms-auto col-4"
				placeholder-text="Search My References"
				@update-grid="calcGridData" />

		</div><!--flex-row-->

		<!-- Multi Reference Select Options -->
		<slide-up-down :active="isReferenceOptionsOpen" class="pb-1">
			<button class="btn btn-sm btn-outline-primary border-0" @click.prevent="btnSelectAllReferences" v-show="isReferenceOptionsOpen">
				<span class="svg bi-plus" /> Select All
			</button>

			<button class="btn btn-sm btn-outline-primary border-0" @click.prevent="btnClearSelectedReferences" v-show="isReferenceOptionsOpen && (selectedGenesisReferenceIds.length > 0 || selectedLegacyReferenceIds.length > 0)">
				<span class="svg bi-x-lg" /> Clear Selection
			</button>

			<button 
				:class="['btn btn-sm btn-outline-danger border-0',
					{
						'perrla-disabled' : selectedGenesisReferenceIds.length === 0 && selectedLegacyReferenceIds.length === 0
					}
				]"
				@click.prevent="btnArchiveSelectedReferences"
				v-show="isReferenceOptionsOpen && !isShowArchivedReferneces">
				<span class="svg bi-dash" /> Archive Selected References
			</button>

			<button
				:class="['btn btn-sm btn-outline-success border-0',
					{
						'perrla-disabled' : selectedGenesisReferenceIds.length === 0 && selectedLegacyReferenceIds.length === 0
					}
				]"
				@click.prevent="btnRestoreSelectedReferences"
				v-show="isReferenceOptionsOpen && isShowArchivedReferneces">
				<span class="svg bi-plus" /> Restore Selected References
			</button>

			<button
				v-show="isReferenceOptionsOpen"
				:class="['btn btn-sm btn-outline-danger border-0',
					{
						// 'perrla-disabled' : selectedGenesisReferenceIds.length > 0 || selectedLegacyReferenceIds.length > 0
					}
				]"
				@click.prevent="btnToggleShowArchived">
				<span v-show="!isShowArchivedReferneces">Show </span>
				<span v-show="isShowArchivedReferneces">Hide </span>
				Archived References
			</button>

			<button
				:class="['btn btn-sm btn-outline-success border-0',
					{
						'perrla-disabled' : selectedGenesisReferenceIds.length === 0 && selectedLegacyReferenceIds.length === 0
					}
				]"
				@click.prevent="btnShareSelectedReferences"
				v-show="$store.state.customer.role !== $enums.Status.FREE_TRIAL && $store.state.app.showBetaFeatures && isReferenceOptionsOpen && !isShowArchivedReferneces">
				<span class="svg bi-arrow-return-right" /> Share Selected References
			</button>


		</slide-up-down>
	
		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative" ref="divReferenceListWrp">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Title"
								:colspan="2"
								sort-key="title"
								:is-local-storage="true"
								:is-title="true"
								:entity-id="entityId"
								@update-grid="calcGridData" />
							
							<th class="table-spacer" />

							<sortable-grid-th
								cell-text="Created"
								set-width="175px"
								sort-key="createdDate"
								:is-local-storage="true"
								:is-title="true"
								:entity-id="entityId"
								@update-grid="calcGridData" />

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<reference-row
							v-for="(reference, index) in filteredGridData"
							:key="index"
							:reference-data="reference"
							@add-reference-selection="addReferenceSelection"
							@remove-reference-selection="removeReferenceSelection"
							@update-grid="calcGridData" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No references found.</p>
				</div>
			</div>
		</div>

		<route-modal />

		<modal-archive-all-references />

		<modal-share-references />
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import CleanReferenceValue from '@/helpers/clean-reference-value';
import config from '@/config';
import GridSearch from '@/components/EntityGrid/Search';
import mixinResizer from '@/mixins/resizer';
import ModalArchiveAllReferences from '@/components/Modals/ArchiveAllReferences';
import ModalShareReferences from './ReferenceList/modals/ShareReferences';
import ReferenceRow from '@/components/EntityGrid/ReferenceRow';
import RouteModal from '@/components/modals/RouteModal';
import SlideUpDown from '@/components/VueSlideUpDown.vue';
import SortableGridTh from '@/components/SortableGridTh';
import Swal from 'sweetalert2';

export default {
	name: 'ReferenceList',
	mixins: [mixinResizer],
	data() {
		return {
			archivedReferneces: [],
			isReferenceOptionsOpen: false,
			isShowArchivedReferneces: false,
			filteredGridData: [],
			selectedGenesisReferenceIds: [],
			selectedLegacyReferenceIds :[],

			rzIgnoreBreakpoints: false,
			rzRef: 'divReferenceListWrp',
		}
	},
	computed:{
		entityId(){
			return config.enums.Entity.REFERENCE;
		},
		preferredFormatVersionIdOutput(){
			switch(this.$store.state.references.displayFormatVersionId){
				case config.enums.Format.MLA9:
					return 'MLA 9';
				case config.enums.Format.Turabian9:
					return 'Turabian 9';
			}
			return 'APA 7';
		},
	},
	methods: {
		addReferenceSelection($opts){
			if($opts.isLegacy){
				if(!this.selectedLegacyReferenceIds.includes($opts.referenceId)){
					this.selectedLegacyReferenceIds.push($opts.referenceId);
					this.$nextTick(()=>{
						if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){
							this.isReferenceOptionsOpen = true;
						}
						this.calcHeight();
					});
				}

			} else {
				if(!this.selectedGenesisReferenceIds.includes($opts.referenceId)){
					this.selectedGenesisReferenceIds.push($opts.referenceId);
					this.$nextTick(()=>{
						if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){
							this.isReferenceOptionsOpen = true;
						}
						this.calcHeight();
					});
				}

			}
		},//e:addReferenceSelection
		
		btnArchiveSelectedReferences(){
			// first make sure there are references selected, the button is disabled but you never know how things break
			if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){

				if((this.selectedGenesisReferenceIds.length + this.selectedLegacyReferenceIds.length) >= this.filteredGridData.length){
					this.$store.commit('modals/OPEN', 'ArchiveAllReferences');
				} else {
					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: 'Are you sure you want to archive these references?',
						html: `
							<p>Archived references will no longer show up in your Reference Library. However, they are not removed from existing papers.</p>
						`,
						icon: 'question',
						cancelButtonText: 'Nevermind',
						confirmButtonText: 'Archive Selected References',
						showCancelButton: true,
						showCloseButton: true,
						showConfirmButton: true,
						customClass:{
							cancelButton: 'btn btn-primary',
							confirmButton: 'btn btn-outline-danger me-2',
						},

					}).then((result) => {
						if (result.value) {
							// console.log(this.selectedGenesisReferenceIds);
							// console.log(this.selectedLegacyReferenceIds);

							let deleteReferenceIdsObject = {
								'iDs': this.selectedGenesisReferenceIds,
								'legacyIDs': this.selectedLegacyReferenceIds,
							}
							
							ApiS_MakeCall({
								body: deleteReferenceIdsObject,
								method: 'DELETE',
								url: 'v4/GenesisReference',
							}).then(()=>{
								this.$store.commit('loaders/ADD_ID', 'App');
								this.$store.commit('REFERENCE_LIBRARY', []);
								this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
								this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
								this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
								
								this.$store.commit('LAST_SYNC_DATE', '');
								
								this.emitter.emit('clearAllSelectedReferences');
								this.selectedGenesisReferenceIds = [];
								this.selectedLegacyReferenceIds = [];

								// nexttick so the vuex store can get all the updates, before doing a full resync
								this.$nextTick(()=>{

									AS_SyncData({
										isStealthSync: true

									}).then(()=>{
										this.calcGridData();

										this.$nextTick(()=>{
											this.calcHeight();
											this.$store.commit('loaders/REMOVE_ID', 'App');
										});
									});
								});
							});//e:ApiS_MakeCall

						}//e:if

					});//e:Swal
			
				}//e:if isAllReferenceSelected

			}
		},//e:btnArchiveSelectedReferences

		btnCreateReference(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReferenceListCreateReferenceStep1',
				modalParams: {
					openReferenceTab: 'common',
				},
			});
		},//e:btnCreateReference
		
		btnClearSelectedReferences(){
			this.emitter.emit('clearAllSelectedReferences');
			this.selectedGenesisReferenceIds = [];
			this.selectedLegacyReferenceIds = [];
			
			this.$nextTick(()=>{
				this.calcHeight();
			});
		},//e:btnClearSelectedReferences

		// btnRebuildGrid(){
		// 	this.$store.commit('loaders/ADD_ID', 'App');
		// 	this.$store.commit('REFERENCE_LIBRARY', []);
		// 	this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
		// 	this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
		// 	this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
		// 	this.$store.commit('LAST_SYNC_DATE', '');

		// 	// nexttick so the vuex store can get all the updates, before doing a full resync
		// 	this.$nextTick(()=>{
		// 		AS_SyncData({
		// 			isStealthSync: true
		// 		}).then(()=>{
		// 			this.$store.commit('loaders/REMOVE_ID', 'App');
		// 			this.emitter.emit('globalToasterOpen',{
		// 				textContent: 'References rebuilt',
		// 			});
		// 		});
		// 	});
		// },//e:btnRebuildGrid
		
		btnRestoreSelectedReferences(){
			// first make sure there are references selected, the button is disabled but you never know how things break
			if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){
				// console.log('btnRestoreSelectedReferences')

				let restoreReferenceIdsObject = {
					'iDs': this.selectedGenesisReferenceIds,
					'legacyIDs': this.selectedLegacyReferenceIds,
				}

				// console.log('restoreReferenceIdsObject');
				// console.log(restoreReferenceIdsObject);
				
				ApiS_MakeCall({
					body: restoreReferenceIdsObject,
					method: 'PUT',
					url: 'v4/GenesisReference/Restore',
				}).then(()=>{
					this.$store.commit('loaders/ADD_ID', 'App');
					this.$store.commit('REFERENCE_LIBRARY', []);
					this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
					this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
					this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
					this.$store.commit('LAST_SYNC_DATE', '');
					
					this.emitter.emit('clearAllSelectedReferences');
					this.selectedGenesisReferenceIds = [];
					this.selectedLegacyReferenceIds = [];

					this.isShowArchivedReferneces = false;

					// nexttick so the vuex store can get all the updates, before doing a full resync
					this.$nextTick(()=>{

						AS_SyncData({
							isStealthSync: true

						}).then(()=>{
							this.calcGridData();

							this.$nextTick(()=>{
								this.calcHeight();
								this.$store.commit('loaders/REMOVE_ID', 'App');
							});
						});
					});
				});//e:ApiS_MakeCall

			}//e:if
		},//e:btnRestoreSelectedReferences

		btnSelectAllReferences(){
			this.emitter.emit('selectAllReferences');
		},//e:btnSelectAllReferences

		btnShareSelectedReferences(){
			this.$store.commit('modals/OPEN', 'ShareReferences');
			this.$store.commit('modals/SET_MODAL_DATA', {
				referencesIdsToShare: this.selectedGenesisReferenceIds,	
			});
		},//e:btnShareSelectedReferences
		
		btnToggleShowArchived(){
			this.isShowArchivedReferneces = !this.isShowArchivedReferneces;

			// 'force clear' any selected referneces 
			this.selectedGenesisReferenceIds = [];
			this.selectedLegacyReferenceIds = [];
			this.emitter.emit('clearAllSelectedReferences');

			this.$nextTick(()=>{
				this.calcGridData();
			});
		},//e:btnToggleShowArchived

		btnToggleReferenceOptionsOpen(){
			this.isReferenceOptionsOpen = !this.isReferenceOptionsOpen;
			if(!this.isReferenceOptionsOpen) {
				this.btnClearSelectedReferences();
			}
		},//e:btnToggleReferenceOptionsOpen

		calcGridData(){
			// console.log('calcGridData');
			
			let dataLegacyReferences = _cloneDeep(this.$store.state.referenceLibrary);
			let dataGenesisReferences = _cloneDeep(this.$store.state.referenceLibraryGenesis);
			
			let entireReferenceLibrary = dataLegacyReferences.concat(dataGenesisReferences);

			let searchedArray = [];
			let isTagSearch = false;
			let _searchTerm = this.$store.state.gridSorts.searchTerm;

			if(typeof _searchTerm !== 'undefined' && _searchTerm.length > 0){
				if(_searchTerm.charAt(0) === '#'){
					isTagSearch = true;
					_searchTerm = _searchTerm.substring(1);
				} 
				_searchTerm = _searchTerm.toLowerCase();
			}

			// loop through each reference and prep for display
			entireReferenceLibrary.forEach((ref) =>{
				if(this.isShowArchivedReferneces){
					if(!ref.isArchived){
						return false;	// skip references that are ACTIVE
					}
				} else {
					if(ref.isArchived){
						return false;	// skip references that are ARCHIVED
					}
				}

				// Parse the display name 
				if(_has(ref, 'values')){
					// since it has values it should be a genesis reference
					
					// Parse the display name 
					let refValues = JSON.parse(ref.values);
					// console.log(refValues);
					if(_isEmpty(refValues)){
						ref.displayValue = '';
					} else {
						ref.displayValue = CleanReferenceValue(refValues.apa7.value);
					}

					ref.legacyReferenceData = '';
					
					// console.log(ref);
					
				} else {
					// APA 6 or MLA 8
					if(ref.formatVersionID === config.enums.Format.APA6){
						// APA6
						ref.displayValue = CleanReferenceValue(ref.value);
					} else {
						// Everything Else
						ref.displayValue = CleanReferenceValue(ref.value);
					}
				}

				// Search by value if exisits
				if(_searchTerm){
					if(isTagSearch){
						// search by tag
						if(ref.tags && ref.tags.toLowerCase().includes(_searchTerm)){
							searchedArray.push(ref);
						}
					} else {
						// search by all
						if(
							(ref.displayValue && ref.displayValue.toLowerCase().includes(_searchTerm)) ||
							(ref.legacyReferenceData && ref.legacyReferenceData.toLowerCase().includes(_searchTerm)) ||
							(ref.nickname && ref.nickname.toLowerCase().includes(_searchTerm)) ||
							(ref.tags && ref.tags.toLowerCase().includes(_searchTerm))
						){
							searchedArray.push(ref);
						}
					}
				} else {
					// Not searching just pass through
					searchedArray.push(ref);
				}//e:_searchTerm
			});//e:forEach
						
			switch(this.$store.state.gridSorts.referenceListOrderBy){
				case 'title':
					if(this.$store.state.gridSorts.referenceListSorts['title'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), [(gridData) => gridData.displayValue.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), [(gridData) => gridData.displayValue.toLowerCase()], ['desc']);
					}
					break;
				case 'createdDate':
					if(this.$store.state.gridSorts.referenceListSorts['createdDate'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['asc', 'asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['desc', 'asc']);
					}
					break;
			}//e:switch

			this.$nextTick(()=>{
				this.calcHeight();
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});

		},//e:calcGridData
		
		removeReferenceSelection($opts){
			if($opts.isLegacy){
				let findIndex = this.selectedLegacyReferenceIds.findIndex((referenceId)=>{
					return $opts.referenceId === referenceId;
				});
				if(findIndex > -1){
					this.selectedLegacyReferenceIds.splice(findIndex, 1);
					this.$nextTick(()=>{
						if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){
							this.isReferenceOptionsOpen = true;
						}
						this.calcHeight();
					});
				}

			} else {
				let findIndex = this.selectedGenesisReferenceIds.findIndex((referenceId)=>{
					return $opts.referenceId === referenceId;
				});
				if(findIndex > -1){
					this.selectedGenesisReferenceIds.splice(findIndex, 1);
					this.$nextTick(()=>{
						if(this.selectedGenesisReferenceIds.length > 0 || this.selectedLegacyReferenceIds.length > 0){
							this.isReferenceOptionsOpen = true;
						}
						this.calcHeight();
					});
				}
			}
		},//e:removeReferenceSelection
		
		setPreferredFormatVersionId(enumUpdateTo){
			this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', enumUpdateTo);
			this.calcGridData();
		},//e:setPreferredFormatVersionId
	},
	created(){
		this.emitter.on('updateReferenceList', ()=>{
			this.calcGridData();
		});
		
	},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App');
		
		AS_SyncData({
			isStealthSync: true
		}).then(()=>{
			// this.$store.commit('loaders/REMOVE_ID', 'App');
			this.$store.dispatch('referenceRules/deepClear').then(()=>{
				this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', this.$store.state.customer.preferredFormatVersionID);

				this.calcGridData();
			});
		});
	},
	components: {
		GridSearch,
		ModalArchiveAllReferences,
		ModalShareReferences,
		ReferenceRow,
		RouteModal,
		SlideUpDown,
		SortableGridTh
	},
	destroyed(){
		this.emitter.off('updateReferenceList');
		this.$store.commit('loaders/REMOVE_ID', 'App');
	},
}
</script>
